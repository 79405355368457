<script>
import { useStore } from 'vuex'
import {computed, onMounted, reactive, toRefs} from 'vue'

const stepName = 'Treated_Or_Prescribed_Last_Five_Years'

const keys = [
  'Anxiety / depression / bipolar',
  'Cancer',
  'Chronic Pain',
  'Diabetes',
  'Heart of circulatory disorder',
  'Respiratory disorder',
  'Other medical condition',
  'I have no medical conditions'
]

export default {
  name: stepName,

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    const state = reactive({
      anxiety_depression_bipolar: false,
      cancer: false,
      chronic_pain: false,
      diabetes: false,
      heart_or_circulatory_disorder: false,
      respiratory_disorder: false,
      other_medical_conditions: false,
      have_no_medical_conditions: false
    })

    const anyConditionsFlagged = computed(() => state.anxiety_depression_bipolar || state.cancer || state.chronic_pain || state.diabetes || state.heart_or_circulatory_disorder || state.respiratory_disorder || state.other_medical_conditions)

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const setAnswer = () => {
      //if user selects anxiety depression, show anxiety/depression question
      //if user selects other, show question asking if any of the listed conditions are visible question
      //if user selects anxiety AND other, other will take priority
      //if user selects NONE, have no medical conditions will be set to 1

      const condition_fields = [
        {field: 'anxiety_depression_bipolar', value: state.anxiety_depression_bipolar ? 1 : 0},
        {field: 'cancer', value: state.cancer ? 1 : 0},
        {field: 'chronic_pain', value: state.chronic_pain ? 1 : 0},
        {field: 'diabetes', value: state.diabetes ? 1 : 0},
        {field: 'heart_or_circulatory_disorder', value: state.heart_or_circulatory_disorder ? 1 : 0},
        {field: 'respiratory_disorder', value: state.respiratory_disorder ? 1 : 0},
        {field: 'other_medical_conditions', value: state.other_medical_conditions ? 1 : 0},
        {field: 'have_no_medical_conditions', value: state.have_no_medical_conditions ? 1: 0}
      ]

      store.commit('SET_FIELDS', condition_fields)
      emit('next-step', stepName)
    }

    const resetConditions = () => {
      state.anxiety_depression_bipolar = false
      state.cancer = false
      state.chronic_pain = false
      state.diabetes = false
      state.heart_or_circulatory_disorder = false
      state.respiratory_disorder = false
      state.other_medical_conditions = false
    }

    const checkFlaggedConditions = () => {
      if (anyConditionsFlagged.value) {
        state.have_no_medical_conditions = false
      }

      if (!anyConditionsFlagged.value) {
        state.have_no_medical_conditions = true
      }
    }

    const handler = (noneSelected) => {
      if (noneSelected) {
        resetConditions()
      }

      checkFlaggedConditions()
    }

    const toggleAnswer = (id) => {
      state[id] = !state[id]

      if (id === 'have_no_medical_conditions') {
        resetConditions()
      }

      checkFlaggedConditions()
    }

    return {
      ...toRefs(state),
      keys,
      setAnswer,
      handler,
      toggleAnswer,
    }
  }
}
</script>

<template>
  <div>
    <div class="row checkbox-container-row">
      <div class="box">
        <div class="checkbox-container">
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="anxiety_depression_bipolar" @change="handler(false)">
            <label class="checkbox-label" @click="toggleAnswer('anxiety_depression_bipolar')">{{keys[0]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="cancer" @change="handler(false)">
            <label class="checkbox-label" @click="toggleAnswer('cancer')">{{keys[1]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="chronic_pain" @change="handler(false)">
            <label class="checkbox-label" @click="toggleAnswer('chronic_pain')">{{keys[2]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="diabetes" @change="handler(false)">
            <label class="checkbox-label" @click="toggleAnswer('diabetes')">{{keys[3]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="heart_or_circulatory_disorder" @change="handler(false)">
            <label class="checkbox-label" @click="toggleAnswer('heart_or_circulatory_disorder')">{{keys[4]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="respiratory_disorder" @change="handler(false)">
            <label class="checkbox-label" @click="toggleAnswer('respiratory_disorder')">{{keys[5]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="other_medical_conditions" @change="handler(false)">
            <label class="checkbox-label" @click="toggleAnswer('other_medical_conditions')">{{keys[6]}}</label>
          </div>
          <div class="checkbox-row">
            <input class="checkbox-input" type="checkbox" v-model="have_no_medical_conditions" @change="handler(true)">
            <label class="checkbox-label" @click="toggleAnswer('have_no_medical_conditions')">{{keys[7]}}</label>
          </div>
        </div>

        <button
          class="main-button main-button-w mt-lg"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/treated_or_prescribed_last_five_years';
</style>